import React from "react";
// component imports
import TabHeading from "../../UI/TabHeading/TabHeading";
import VirtualAccountsDataTable from "../SortTableSummary/VirtualAccountsDataTable";
// styles imports
import "./VirtualAccounts.scss";


const VirtualAccounts = () => {

    return (
        <div className="virtual-accounts-page">
            <div className="virtual-accounts-content">
                <div className="virtual-accounts">
                    <TabHeading headingText="Virtual Accounts" />
                </div>
                <div className="virtual-accounts-card">
                    <VirtualAccountsDataTable />
                </div>
            </div>
        </div>
    );
};

export default VirtualAccounts;