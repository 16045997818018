import React, { createContext, useState } from "react";

const VirtualAccountsContext = createContext();

export const VirtualAccountsProvider = ({ children }) => {
    return (
        <VirtualAccountsContext.Provider
            value={{
            }}
        >
            {children}
        </VirtualAccountsContext.Provider>
    );
};

export default VirtualAccountsContext;
