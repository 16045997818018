import React, { useState, useEffect, useCallback } from "react";
import ReactDOM from "react-dom";

//formik-import
import { useFormik } from "formik";
import * as Yup from "yup";

//API-imports
import axios from "axios";
import { APIConfig } from "../../services/apiConfiguration";
import paAPIEndpoints from "../../config/pa_api_endpoints/manage_customer_pa/endpoint";
import apiEndpointList from "../../config/modules/customer_management/endpoint";

// utils-import
import { randomUUID } from "../../services/randomUUID";

//component-import
import TextFieldSelect from "../../UI/TextField/TextFieldSelect";
import SnackbarMessage from "../SnackbarMessage/SnackbarMessage";
import ResponseDetails from "./ResponseDetails/ResponseDetails";
import { TotpModal } from "./TotpModal/TotpModal";

//styles-import
import "../../UI/TextField/TextField.scss";

const initialValues = {
  company_name: "",
};

const validationSchema = Yup.object({
  company_name: Yup.object().shape({
    value: Yup.number().required("Please Select an Option"),
    label: Yup.string().required("Please Select an Option"),
  }),
});

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const ReTriggerCredentials = () => {
  const [companyOptions, setCompanyOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [responseDetails, setResponseDetails] = useState({});
  const [showResponseDetails, setShowResponseDetails] = useState(false);

  const [dataToVerify, setDataToVerify] = useState({
    reference_id: "",
    consent: true,
  });
  const [qrBase, setQrBase] = useState("");
  const [showTotp, setShowTotp] = useState(false);
  const [showTotpModal, setShowTotpModal] = useState(false);
  const [isCompanySelected, setIsCompanySelected] = useState(false);

  const handleClick = () => {
    setShowResponseDetails(false);
    setResponseDetails({});
  };

  const getSelectedCompanies = () => {
    return JSON.parse(localStorage.getItem("selectedCompanies") || "[]");
  };

  const addCompanyToLocalStorage = (companyId) => {
    const selectedCompanies = getSelectedCompanies();
    if (!selectedCompanies.includes(companyId)) {
      selectedCompanies.push(companyId);
      localStorage.setItem(
        "selectedCompanies",
        JSON.stringify(selectedCompanies)
      );
    }
  };

  const sendCredentialsAction = (formik) => {
    const { values } = formik;
    const payload = {
      reference_id: randomUUID(),
      company_id: values?.company_name?.value,
    };

    formik.setSubmitting(true);

    APIConfig.API_Client.post(
      paAPIEndpoints.SEND_ONBOARDING_CREDENTIALS.baseUrl +
      paAPIEndpoints.SEND_ONBOARDING_CREDENTIALS.endpoint,
      payload,
      { cancelToken: source.token }
    )
      .then((response) => {
        setResponseDetails(response.data);
        addCompanyToLocalStorage(values.company_name.value);

        formik.resetForm();
        setIsCompanySelected(false); // Reset to false after form reset
        ReactDOM.render(
          <SnackbarMessage
            msgtype="success"
            msg={response?.data?.message || "Credentials sent Successfully"}
          />,
          document.getElementById("snackbar")
        );
        setShowResponseDetails(true);
      })
      .catch((error) => {
        setResponseDetails(error.response.data);
        ReactDOM.render(
          <SnackbarMessage
            msgtype="Error"
            msg={error?.response?.data?.message || "Something went wrong !"}
          />,
          document.getElementById("snackbar")
        );
        setShowResponseDetails(true);
      })
      .finally(() => {
        formik.setSubmitting(false);
      });
  };

  const email = JSON.parse(localStorage.getItem("user")).email;
  const google_user_token = localStorage.getItem("google_user_token");

  const submitHandler = (values, action) => {
    const payload = {
      source: "ADMIN",
      consent: true,
      reference_id: randomUUID(),
      google_user_token,
      email,
    };

    setIsLoading(true);
    APIConfig.API_Client.post(
      paAPIEndpoints.GENERATE_TOTP.baseUrl +
      paAPIEndpoints.GENERATE_TOTP.endpoint,
      payload
    )
      .then((res) => {
        setQrBase("");
        setIsLoading(false);
        if (res.status === 200) {
          if (res?.data?.qr_code) {
            setQrBase(res.data.qr_code);
            setShowTotp(false);
          } else {
            setQrBase("");
            setShowTotp(true);
          }
          setDataToVerify((prev) => ({
            ...prev,
            reference_id: payload.reference_id,
            totp_token: res.data.totp_token,
          }));
        }
        setShowTotpModal(true);
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, action) => {
      submitHandler(values, action);
    },
  });

  const getCompanyOptions = useCallback(() => {
    setIsLoading(true);
    APIConfig.API_Client.get(
      apiEndpointList.GET_ALL_COMPANY_DETAIL.baseUrl +
      apiEndpointList.GET_ALL_COMPANY_DETAIL.endpoint,
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        const options = response.data.data.map((item) => ({
          value: item.company_id,
          label: item.common_name,
        }));

        setCompanyOptions(options);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error while fetching companies list:", error);
        setCompanyOptions([]);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    const selectedCompanyId = formik.values.company_name?.value;
    if (selectedCompanyId) {
      const selectedCompanies = getSelectedCompanies();
      setIsCompanySelected(selectedCompanies.includes(selectedCompanyId));
    } else {
      setIsCompanySelected(false);
    }
  }, [formik.values.company_name]);

  useEffect(() => {
    getCompanyOptions();
  }, [getCompanyOptions]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="ui-form-details">
          <div className="ui-form-content ui-divider">
            <div className="ui-form-inputs-section">
              <TextFieldSelect
                id="company_name"
                name="company_name"
                onChange={(selectedOption) => {
                  formik.setFieldValue("company_name", selectedOption);
                  const companyId = selectedOption?.value;
                  if (companyId) {
                    const selectedCompanies = getSelectedCompanies();
                    setIsCompanySelected(selectedCompanies.includes(companyId));
                  } else {
                    setIsCompanySelected(false);
                  }
                }}
                onBlur={() => formik.setFieldTouched("company_name", true)}
                value={formik.values.company_name}
                options={companyOptions}
                noOptionsMessage={() => "No such company exists"}
                label="Company"
                isToolTip={"Dashboard passwords are not sent with this feature"}
                required={true}
                isLoading={isLoading}
                placeholder="Select company name"
                isformatOptionLabel={true}
              />
            </div>

            <div className="ui-button-container">
              <button
                className={`formik-btn-submit ${formik.isValid &&
                    formik.dirty &&
                    !formik.isSubmitting &&
                    !isCompanySelected
                    ? "active"
                    : "disabled"
                  }`}
                type="submit"
                disabled={
                  !formik.isValid || formik.isSubmitting || isCompanySelected
                }
              >
                {formik.isSubmitting
                  ? "Loading..."
                  : isCompanySelected
                    ? "Credentials already sent"
                    : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </form>
      {showTotpModal && (
        <TotpModal
          setShowTotpModal={setShowTotpModal}
          showTotp={showTotp}
          setShowTotp={setShowTotp}
          qrBase={qrBase}
          dataToVerify={dataToVerify}
          submitAfterTotp={sendCredentialsAction}
          formik={formik}
        />
      )}

      {showResponseDetails && (
        <ResponseDetails data={responseDetails} onClick={handleClick} />
      )}
    </>
  );
};

export default ReTriggerCredentials;