import React, { useContext, useRef, useState } from "react";
import ReactDom from "react-dom";
// component styles
import CloseIcon from "@material-ui/icons/Close";
// styles imports
import "./ReportsSuccessModal.scss";

export const ReportsSuccessModal = ({ setShowModal, message, url }) => {
  const [responseState, setResponseState] = useState([]);

  // close the modal when clicking outside the modal.
  const modalRef = useRef();
  const closeModal = (e) => {
    if (e.target === modalRef.current) {
      setShowModal(false);
    }
  };

  return ReactDom.createPortal(
    <div className="container" ref={modalRef} onClick={closeModal}>
      <div className="modal">
        {/* close modal icon */}
        <button className="close-modal-btn" onClick={() => setShowModal(false)}>
          <CloseIcon />
        </button>

        {/* modal header */}
        <div className="reports-modal-heading">
          <img
            className="success-status-icon"
            src="/images/success-tick.svg"
            alt="success"
          />
          <p>Report Generated Successfully</p>
        </div>

        {/* Display the success message */}
        <div>
          <p className="reports-modal-subtitle">
            Your report has been generated and will be sent to your email
            address.
          </p>
          {url && (
            <p>
              Download your report{" "}
              <a href={url} target="_blank" rel="noopener noreferrer">
                here
              </a>
              .
            </p>
          )}
        </div>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
