import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";

//formik-import
import { useFormik } from "formik";
import * as Yup from "yup";

//API-imports
import { APIConfig } from "../../services/apiConfiguration";
import apiEndpointList from "../../config/modules/customer_management/endpoint";
import paAPIEndpoints from "../../config/pa_api_endpoints/manage_customer_pa/endpoint";

//component-import
import TextFieldSelect from "../../UI/TextField/TextFieldSelect";
import TextFieldInput from "../../UI/TextField/TextFieldInput";
import TextFieldMultiSelect from "../../UI/TextFieldMultiSelect/TextFieldMultiSelect";
import DateRangePicker from "../../UI/DateRangePicker/DateRangePicker";
import SnackbarMessage from "../SnackbarMessage/SnackbarMessage";
import { ReportsSuccessModal } from "./ReportsSuccessModal";
import MultiInputField from "../../UI/MultiInputField/MultiInputField";

//styles-import
import "../../UI/TextField/TextField.scss";

const initialValues = {
  company: "",
  report_type: "",
  report_format: "",
  email: "",
  date_range: {
    start_date: "",
    end_date: "",
  },
  transaction_types: [],
  payment_link_status: [],
  dynamic_qr_status: [],
  upi_refund_status: [],
  ecollect_transaction_types: [],
  ecollect_status: [],
};

const validationSchema = Yup.object({
  company: Yup.object()
    .shape({
      value: Yup.string().required("Please select a company"),
      label: Yup.string().required("Please select a company"),
    })
    .required("Company is required"),
  report_type: Yup.object()
    .shape({
      value: Yup.string().required("Please select a report type"),
      label: Yup.string().required("Please select a report type"),
    })
    .required("Report type is required"),
  report_format: Yup.object()
    .shape({
      value: Yup.string().required("Please select a report format"),
      label: Yup.string().required("Please select a report format"),
    })
    .required("Report format is required"),
  date_range: Yup.object({
    start_date: Yup.date().required("Start date is required"),
    end_date: Yup.date()
      .required("End date is required")
      .min(Yup.ref("start_date"), "End date must be after start date"),
  }).required("Date range is required"),
  email: Yup.array()
    .of(
      Yup.string().email("Invalid email format").required("Email is required")
    )
    .max(3, "You can only enter up to 3 email addresses")
    .required("At least one email address is required")
    .test(
      "at-least-one-email-address",
      "At least one Email Address is required",
      (value) => value && value.length > 0
    ),
  // UPI specific validations
  transaction_types: Yup.array().when("report_type", {
    is: (report_type) => report_type?.value === "upi",
    then: (schema) => schema.optional(),
    otherwise: (schema) => schema.notRequired(),
  }),
  payment_link_status: Yup.array().when(["report_type", "transaction_types"], {
    is: (report_type, transaction_types) =>
      report_type?.value === "upi" &&
      transaction_types?.some((type) => type.value === "payment_links"),
    then: (schema) => schema.optional(),
    otherwise: (schema) => schema.notRequired(),
  }),
  dynamic_qr_status: Yup.array().when(["report_type", "transaction_types"], {
    is: (report_type, transaction_types) =>
      report_type?.value === "upi" &&
      transaction_types?.some((type) => type.value === "dynamic_qr"),
    then: (schema) => schema.optional(),
    otherwise: (schema) => schema.notRequired(),
  }),
  upi_refund_status: Yup.array().when(["report_type", "transaction_types"], {
    is: (report_type, transaction_types) =>
      report_type?.value === "upi" &&
      transaction_types?.some((type) => type.value === "upi_refunds"),
    then: (schema) => schema.optional(),
    otherwise: (schema) => schema.notRequired(),
  }),
  // E-Collect specific validations
  ecollect_transaction_types: Yup.array().when("report_type", {
    is: (report_type) => report_type?.value === "ecollect",
    then: (schema) => schema.optional(),
    otherwise: (schema) => schema.notRequired(),
  }),
  ecollect_status: Yup.array().when("report_type", {
    is: (report_type) => report_type?.value === "ecollect",
    then: (schema) => schema.optional(),
    otherwise: (schema) => schema.notRequired(),
  }),
}).test(
  "at-least-one-field",
  "At least one optional field must be selected",
  function (values) {
    if (values.report_type?.value === "upi") {
      const hasTransactionTypes = values.transaction_types?.length > 0;
      const hasPaymentLinkStatus = values.payment_link_status?.length > 0;
      const hasDynamicQrStatus = values.dynamic_qr_status?.length > 0;
      const hasUpiRefundStatus = values.upi_refund_status?.length > 0;

      if (hasPaymentLinkStatus || hasDynamicQrStatus || hasUpiRefundStatus) {
        return hasTransactionTypes;
      }
      return true;
    } else if (values.report_type?.value === "ecollect") {
      return true;
    }
    return true;
  }
);

const Reports = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [downloadUrl, setDownloadUrl] = useState("");

  const paymentModeOptions = [
    { value: "upi", label: "UPI" },
    { value: "ecollect", label: "E-Collect" },
  ];

  const reportFormatOptions = [
    { value: "xlsx", label: "XLSX" },
    { value: "csv", label: "CSV" },
    { value: "json", label: "JSON" },
  ];

  const transactionTypeOptions = [
    { value: "payment_links", label: "Payment Links" },
    { value: "dynamic_qr", label: "Dynamic QR" },
    { value: "upi_refunds", label: "UPI Refunds" },
  ];

  const eCollectTransactionTypeOptions = [
    { value: "rtgs", label: "RTGS" },
    { value: "neft", label: "NEFT" },
    { value: "imps", label: "IMPS" },
  ];

  const eCollectStatusOptions = [
    { value: "success", label: "Success" },
    { value: "failure", label: "Failure" },
  ];

  const reportTypeOptions = [
    { value: "upi", label: "UPI" },
    { value: "ecollect", label: "E-Collect" },
  ];

  // Updated options for payment_link_status
  const paymentLinkStatusOptions = [
    { value: "success", label: "Success" },
    { value: "failed", label: "Failed" },
    { value: "expired", label: "Expired" },
    { value: "hold", label: "Hold" },
    { value: "deemed", label: "Deemed" },
    { value: "captured", label: "Captured" },
  ];

  // Updated options for dynamic_qr_status
  const dynamicQrStatusOptions = [
    { value: "success", label: "Success" },
    { value: "failure", label: "Failure" },
    { value: "expired", label: "Expired" },
    { value: "hold", label: "Hold" },
  ];

  // Updated options for upi_refund_status
  const upiRefundStatusOptions = [
    { value: "refunded", label: "Refunded" },
    { value: "refund_failed", label: "Refund failed" },
  ];

  useEffect(() => {
    setIsLoading(true);
    APIConfig.API_Client.get(
      apiEndpointList.GET_ALL_COMPANY_DETAIL.baseUrl +
        apiEndpointList.GET_ALL_COMPANY_DETAIL.endpoint
    )
      .then((response) => {
        const options = response.data.data.map((item) => ({
          value: item.company_id,
          label: item.common_name,
        }));
        setCompanyOptions(options);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error while fetching companies list:", error);
        setCompanyOptions([]);
        setIsError(true);
        setIsLoading(false);
      });
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, action) => {
      const payload = {
        company_id: values.company.value,
        start_date: values.date_range.start_date.toISOString().slice(0, 10),
        end_date: values.date_range.end_date.toISOString().slice(0, 10),
        common_name: values.company.label,

        ...(values.report_type.value === "upi" && {
          collection_type: [
            ...values.transaction_types.map((type) => {
              if (type.value === "upi_refunds") return "refund";
              if (type.value === "payment_links") return "payment_link";
              if (type.value === "dynamic_qr") return "dynamic_qr";
              return null;
            }),
          ]
            .filter(Boolean)
            .join(","),
        }),
        ...(values.report_type.value === "ecollect" && {
          transaction_type: [
            ...values.ecollect_transaction_types.map((type) => type.value),
          ]
            .filter(Boolean)
            .join(","),
        }),
        status: [
          ...values.payment_link_status.map((status) => {
            if (status.value === "success") return "success";
            if (status.value === "failed") return "failed";
            if (status.value === "expired") return "expired";
            if (status.value === "hold") return "hold";
            if (status.value === "deemed") return "deemed";
            if (status.value === "captured") return "captured";
            return null;
          }),
          ...values.dynamic_qr_status.map((status) => {
            if (status.value === "success") return "success";
            if (status.value === "failure") return "failed";
            if (status.value === "expired") return "expired";
            if (status.value === "hold") return "hold";
            return null;
          }),
          ...values.upi_refund_status.map((status) => {
            if (status.value === "refunded") return "refunded";
            if (status.value === "refund_failed") return "refund_failed";
            return null;
          }),
          ...values.ecollect_status.map((status) =>
            status.value.toLowerCase().replace(/ /g, "_")
          ),
        ]
          .filter(Boolean)
          .join(","),
        email_params: {
          to_emails: values.email,
        },
        limit: 100,
        offset: 0,
      };
      action.setSubmitting(true);
      APIConfig.API_Client.post(
        paAPIEndpoints.GET_MIS_REPORT.baseUrl +
          paAPIEndpoints.GET_MIS_REPORT.endpoint,
        payload
      )
        .then((response) => {
          setIsLoading(false);
          setSuccessMessage(
            "The file will be downloadable using the link below in a while."
          );
          setDownloadUrl(response.data.url);
          setShowSuccessModal(true);
          action.setSubmitting(false);
        })
        .catch((error) => {
          ReactDOM.render(
            <SnackbarMessage
              msgtype="Error"
              msg={error?.response?.data?.message || "Something went wrong!"}
            />,
            document.getElementById("snackbar")
          );
          setIsError(true);
          setIsLoading(false);
          action.setSubmitting(false);
        });
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="ui-form-details">
          <div className="ui-divider"></div>
          <div className="ui-form-content">
            <div className="ui-form-inputs-section">
              <TextFieldSelect
                id="company"
                name="company"
                onChange={(selectedOption) => {
                  formik.setFieldValue("company", selectedOption);
                  // Reset dependent fields
                  formik.setFieldValue("report_type", "");
                  formik.setFieldValue("report_format", "");
                  formik.setFieldValue("transaction_types", []);
                  formik.setFieldValue("payment_link_status", []);
                  formik.setFieldValue("dynamic_qr_status", []);
                }}
                onBlur={() => formik.setFieldTouched("company", true)}
                value={formik.values.company}
                options={companyOptions}
                noOptionsMessage={() => "No companies available"}
                label="Company"
                required={true}
                isLoading={isLoading}
                isError={isError}
                placeholder="Select Company"
                isformatOptionLabel={true}
                showOnlyLabelWhenSelected={true}
              />

              {formik.values.company && (
                <TextFieldSelect
                  id="report_type"
                  name="report_type"
                  onChange={(selectedOption) => {
                    formik.setFieldValue("report_type", selectedOption);
                    // Reset dependent fields
                    formik.setFieldValue("report_format", "");
                    formik.setFieldValue("transaction_types", []);
                    formik.setFieldValue("payment_link_status", []);
                    formik.setFieldValue("dynamic_qr_status", []);
                  }}
                  value={formik.values.report_type}
                  options={reportTypeOptions}
                  label="Report Type"
                  required={true}
                  placeholder="Select Report Type"
                />
              )}
            </div>

            {formik.values.report_type?.value === "upi" && (
              <>
                <div className="ui-form-inputs-section">
                  <TextFieldSelect
                    id="report_format"
                    name="report_format"
                    onChange={(selectedOption) =>
                      formik.setFieldValue("report_format", selectedOption)
                    }
                    value={formik.values.report_format}
                    options={reportFormatOptions}
                    label="Report Format"
                    required={true}
                    placeholder="Select Format"
                  />

                  <DateRangePicker
                    startDate={formik.values.date_range.start_date}
                    endDate={formik.values.date_range.end_date}
                    onStartDateChange={(date) =>
                      formik.setFieldValue("date_range.start_date", date)
                    }
                    onEndDateChange={(date) =>
                      formik.setFieldValue("date_range.end_date", date)
                    }
                    required={true}
                    label="Date Range"
                  />
                </div>
                <div className="ui-form-inputs-section">
                  <MultiInputField
                    id="email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.setFieldValue}
                    onBlur={formik.setFieldTouched}
                    touched={formik.touched.email}
                    error={formik.errors.email}
                    placeholder="Enter email address(es)"
                    label="Email Address(es)"
                    required={true}
                    extraHint="Press Enter after entering each email address. Max 3 email addresses allowed."
                  />

                  <TextFieldMultiSelect
                    id="collection_types"
                    name="collection_types"
                    onChange={(selectedOptions) =>
                      formik.setFieldValue("transaction_types", selectedOptions)
                    }
                    value={formik.values.transaction_types}
                    options={transactionTypeOptions}
                    label="Collection Types"
                    placeholder="Select Collection Types"
                  />
                </div>
                <div className="ui-form-inputs-section">
                  {formik.values.transaction_types?.some(
                    (type) => type.value === "payment_links"
                  ) && (
                    <TextFieldMultiSelect
                      id="payment_link_status"
                      name="payment_link_status"
                      onChange={(selectedOptions) =>
                        formik.setFieldValue(
                          "payment_link_status",
                          selectedOptions
                        )
                      }
                      value={formik.values.payment_link_status}
                      options={paymentLinkStatusOptions}
                      label="Payment Link Status"
                      placeholder="Select Status"
                    />
                  )}

                  {formik.values.transaction_types?.some(
                    (type) => type.value === "dynamic_qr"
                  ) && (
                    <TextFieldMultiSelect
                      id="dynamic_qr_status"
                      name="dynamic_qr_status"
                      onChange={(selectedOptions) =>
                        formik.setFieldValue(
                          "dynamic_qr_status",
                          selectedOptions
                        )
                      }
                      value={formik.values.dynamic_qr_status}
                      options={dynamicQrStatusOptions}
                      label="Dynamic QR Status"
                      placeholder="Select Status"
                    />
                  )}
                </div>
                <div className="ui-form-inputs-section">
                  {formik.values.transaction_types?.some(
                    (type) => type.value === "upi_refunds"
                  ) && (
                    <TextFieldMultiSelect
                      id="upi_refund_status"
                      name="upi_refund_status"
                      onChange={(selectedOptions) =>
                        formik.setFieldValue(
                          "upi_refund_status",
                          selectedOptions
                        )
                      }
                      value={formik.values.upi_refund_status}
                      options={upiRefundStatusOptions}
                      label="UPI Refund Status"
                      placeholder="Select Status"
                    />
                  )}
                </div>
              </>
            )}

            {formik.values.report_type?.value === "ecollect" && (
              <>
                <div className="ui-form-inputs-section">
                  <TextFieldSelect
                    id="report_format"
                    name="report_format"
                    onChange={(selectedOption) =>
                      formik.setFieldValue("report_format", selectedOption)
                    }
                    value={formik.values.report_format}
                    options={reportFormatOptions}
                    label="Report Format"
                    required={true}
                    placeholder="Select Format"
                  />

                  <DateRangePicker
                    startDate={formik.values.date_range.start_date}
                    endDate={formik.values.date_range.end_date}
                    onStartDateChange={(date) =>
                      formik.setFieldValue("date_range.start_date", date)
                    }
                    onEndDateChange={(date) =>
                      formik.setFieldValue("date_range.end_date", date)
                    }
                    required={true}
                    label="Date Range"
                  />
                </div>
                <div className="ui-form-inputs-section">
                  <MultiInputField
                    id="email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.setFieldValue}
                    onBlur={formik.setFieldTouched}
                    touched={formik.touched.email}
                    error={formik.errors.email}
                    placeholder="Enter email address(es)"
                    label="Email Address(es)"
                    required={true}
                    extraHint="Press Enter after entering each email address. Max 3 email addresses allowed."
                  />

                  <TextFieldMultiSelect
                    id="ecollect_transaction_types"
                    name="ecollect_transaction_types"
                    onChange={(selectedOptions) =>
                      formik.setFieldValue(
                        "ecollect_transaction_types",
                        selectedOptions
                      )
                    }
                    value={formik.values.ecollect_transaction_types}
                    options={eCollectTransactionTypeOptions}
                    label="Transaction Types"
                    placeholder="Select Transaction Types"
                  />
                </div>
                <div className="ui-form-inputs-section">
                  <TextFieldMultiSelect
                    id="ecollect_status"
                    name="ecollect_status"
                    onChange={(selectedOptions) =>
                      formik.setFieldValue("ecollect_status", selectedOptions)
                    }
                    value={formik.values.ecollect_status}
                    options={eCollectStatusOptions}
                    label="Transaction Status"
                    placeholder="Select Status"
                  />
                </div>
              </>
            )}

            <div className="settlement-details-submit ui-button-container">
              <button
                className={`settlement-details-submit ${
                  formik.isValid && formik.dirty && !formik.isSubmitting
                    ? "active"
                    : ""
                }`}
                type="submit"
                disabled={
                  !formik.isValid || formik.isSubmitting || !formik.dirty
                }
              >
                {formik.isSubmitting ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </form>
      {showSuccessModal && (
        <ReportsSuccessModal
          setShowModal={setShowSuccessModal}
          message={successMessage}
          url={downloadUrl}
        />
      )}
    </>
  );
};

export default Reports;
