import React from "react";
import Select from "react-select";
import ToolTip from "../ToolTip/ToolTip";
import "../TextField/TextField.scss";

const customStyles = {
  control: (provided) => ({
    ...provided,
    border: "1px solid #cbcbcb",
    borderRadius: "10px",
    padding: "5px",
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: "14px",
  }),
  valueContainer: (provided) => ({
    ...provided,
    fontSize: "14px",
  }),
  option: (provided) => ({
    ...provided,
    fontSize: "14px",
    overflowX: "hidden",
    wordWrap: "break-word",
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "#e8f0fe",
    borderRadius: "4px",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    fontSize: "14px",
    color: "#1967d2",
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: "#1967d2",
    ":hover": {
      backgroundColor: "#d2e3fc",
      color: "#1967d2",
    },
  }),
};

const TextFieldMultiSelect = ({
  id,
  name,
  onChange,
  onBlur,
  isLoading,
  value,
  options,
  placeholder,
  label,
  noOptionsMessage,
  required,
  isToolTip,
  isClearable = true,
  isSearchable = true,
  isDisabled = false,
}) => (
  <div className={`ui-form-input-section ui-form-content-input`}>
    <Select
      id={id}
      name={name}
      onChange={onChange}
      onBlur={onBlur}
      placeholder={placeholder}
      value={value}
      options={options}
      styles={customStyles}
      noOptionsMessage={noOptionsMessage}
      isSearchable={isSearchable}
      isClearable={isClearable}
      isDisabled={isDisabled}
      isLoading={isLoading}
      isMulti={true}
      maxMenuHeight={140}
      closeMenuOnSelect={false}
    />
    <label className="ui-label" htmlFor={id}>
      {label}{" "}
      <ToolTip text={isToolTip} requiredField={required} isToolTip={isToolTip}>
        {isToolTip && (
          <div className="ui-info-icon">
            <img src="/images/info-icon.svg" alt="" />
          </div>
        )}
      </ToolTip>
    </label>
  </div>
);

export default TextFieldMultiSelect;
