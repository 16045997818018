import React from "react";
import ReactDOM from "react-dom";

// component imports
import CloseIcon from "@material-ui/icons/Close";
import { Dialog, DialogContent } from "@material-ui/core";
import SnackbarMessage from "../../SnackbarMessage/SnackbarMessage";

// style imports
import "./VADetailsDialog.scss";

// Utils import
import copyToClipboardTable from "../../../utilities/copyToClipboardTable";

function VADetailsDialog({ data, vaNumber }) {
    const closeSuccessDialog = () => {
        ReactDOM.unmountComponentAtNode(
            document.getElementById("add-customer-components")
        );
    };

    const copyAllIps = () => {
        copyToClipboardTable(".configure-ip-data");

        ReactDOM.render(
            <SnackbarMessage
                msgtype="success"
                msg={"IP Address(es) copied to clipboard"}
            />,
            document.getElementById("snackbar")
        );
    };

    return (
        <React.Fragment>
            <Dialog
                className="va-details"
                PaperProps={{ style: { borderRadius: 10 } }}
                open={true}
                aria-labelledby="form-dialog-title"
            >
                <div className="close-popup">
                    <CloseIcon onClick={closeSuccessDialog} />
                </div>

                <DialogContent>
                    <div className="va-details-heading">
                        <p>Details for VA Number <span>
                            {vaNumber}
                        </span></p>
                    </div>
                    <div className="va-details-container">
                        {/* LEFT - BLOCK */}

                        <div className="left-detail-block">
                            <table>
                                <tbody>
                                    {data && data?.length < 1 ?
                                        <p className="no-data-found">No Data Found</p>
                                        :
                                        data?.map((eachVA, index) => (
                                            <div>
                                                <tr>
                                                    <th>Company Name: </th>
                                                    <td> {eachVA.common_name}</td>
                                                </tr>
                                                <tr>
                                                    <th>VA Name: </th>
                                                    <td> {eachVA.virtual_account_name}</td>
                                                </tr>

                                                <tr>
                                                    <th>Bank Provider: </th>
                                                    <td> {eachVA.bank_provider_code}</td>
                                                </tr>
                                                <tr>
                                                    <th>Custom VA Number: </th>
                                                    <td> {eachVA.custom_va_number}</td>
                                                </tr>
                                                <tr>
                                                    <th>Customer VA Identifier: </th>
                                                    <td> {eachVA.customer_va_identifier}</td>
                                                </tr>

                                                <tr>
                                                    <th>Current Balance: </th>
                                                    <td> {eachVA.current_balance}</td>
                                                </tr>
                                                <tr>
                                                    <th>Created On: </th>
                                                    <td> {eachVA.created_on}</td>
                                                </tr>

                                                <tr>
                                                    <th>Consumer URN: </th>
                                                    <td> {eachVA.consumer_urn}</td>
                                                </tr>
                                            </div>

                                        ))}


                                    {/* <tr>
                                        <th>Amount</th>
                                        <td>₹ {FormatBalance(data?.amount)}</td>
                                    </tr>

                                    <tr>
                                        <th>Bank</th>
                                        <td>{data.provider}</td>
                                    </tr>
                                    <tr>
                                        <th>Status</th>
                                        <td>{data.status}</td>
                                    </tr>
                                    <tr>
                                        <th>Decentro URN</th>
                                        <td>{data.decentro_transaction_id}</td>
                                    </tr>

                                    <tr>
                                        <th>Customer Reference Number</th>
                                        <td>{data.customer_reference_number}</td>
                                    </tr>

                                    <tr>
                                        <th>Commission Amount</th>
                                        <td>{FormatBalance(data?.commission_amount)}</td>
                                    </tr>

                                    <tr>
                                        <th>Bank Reference Number</th>
                                        <td>{data.bank_reference_number}</td>
                                    </tr>

                                    <tr>
                                        <th>Date</th>
                                        <td>{formatDateToCustomFormat(data?.date)}</td>
                                    </tr>

                                    <tr>
                                        <th>Payment Method</th>
                                        <td>{data.transaction_type}</td>
                                    </tr> */}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}

export default VADetailsDialog;
